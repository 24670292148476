jQuery(document).on('click','.js-toggle-menu', function(e) {
    e.preventDefault();
    jQuery(this).toggleClass('is-open');
    jQuery('nav').slideToggle(400,function(){
        if($(this).css('display')== 'none')
            $(this).css('display','')
    });
    jQuery('header').siblings('nav').toggleClass('is-open');
});

jQuery(document).on('click','.js-product-menu', function() {
    if (jQuery('.js-toggle-mega-menu').hasClass('slide-up')) {
        jQuery('.js-toggle-mega-menu').removeClass('slide-up');
    } else {
        if (jQuery(window).scrollTop() >= 100) {
            jQuery('.js-toggle-mega-menu').toggleClass('slide-up'); 
        } else {
           jQuery('.js-toggle-mega-menu').toggleClass('slide-down');  
        }
   } 

}); 

jQuery(document).on('click', function(event) {
    if(jQuery(event.target).closest('.js-toggle-mega-menu').length === 0 && jQuery(event.target).closest('.header-navigation').length === 0) {
        if(jQuery('.js-toggle-mega-menu').hasClass('slide-up') || jQuery('.js-toggle-mega-menu').hasClass('slide-down')) {
            jQuery('.js-toggle-mega-menu').removeClass('slide-down slide-up');  
        }
    }
});