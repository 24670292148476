jQuery( document ).ready(function() {
    jQuery('.js-button-toggle-form').click(function(){
       jQuery.when(jQuery('.js-form').toggle('slide')).then(function(){
            jQuery(document).foundation('equalizer', 'reflow');  
       });
       
    }); 

     jQuery('.js-button-send-pdf, .js-download-pdf .js-close').click(function(){
        form = jQuery(this).data('form');
       jQuery('.js-mobile-overlay, #id'+ form).fadeToggle(); 

    });
    jQuery('.js-close').click(function(){ 
         jQuery('.form-success').addClass('hide');
    });


    jQuery('.js-button--share, .js-share-page .js-close').click(function(){
       jQuery('.js-mobile-overlay, .js-share-page').fadeToggle(); 

    });

 
});