String.prototype.ParceLocation = function(){
  var l = document.createElement("a");
  l.href = this;
  return l;
}

jQuery( document ).ready(function() {

  function scrollToAnchor(href) {
    var href = href.ParceLocation()

    if (href.hostname == window.location.hostname && href.pathname == window.location.pathname){//jQuery('body').hasClass('home')) {
      var el = document.querySelector(href.hash);
      if(el != null)
        el.scrollIntoView({block   :"start", behavior: 'smooth' }); 
      else
        window.location.href = href.href
    } else {
      window.location.href = href.href
    }
  }

  jQuery('.js-button--scroll-down').click(function(){
    var href = jQuery(this).data('anchor');
    scrollToAnchor('#' + href);
  });

   jQuery('.mega-menu a').click(function(e){
      e.preventDefault();
      var href = jQuery(this).attr('href');
        jQuery.when(jQuery('.js-toggle-mega-menu').removeClass('slide-up slide-down')).done(function() {
          scrollToAnchor(href);
        });
   });


jQuery('.js-menu-contact a, .js-product-menu-item a').click(function(e){
      e.preventDefault();
       var href = jQuery(this).attr('href');
       if (jQuery('.js-toggle-mega-menu').css('display') == 'block') {
          jQuery.when(jQuery('.js-toggle-mega-menu').removeClass('slide-up slide-down')).done(function() {
             scrollToAnchor(href);
         });
       } else {
          scrollToAnchor(href);
       }
      if (isMobile == true) {
          jQuery('.js-toggle-menu').toggleClass('is-open');
          jQuery('nav').slideToggle();
          jQuery('header').siblings('nav').toggleClass('is-open');
       }
   });
   jQuery('.js-logo').click(function(event) {
      scrollToAnchor((jQuery('html').attr('lang')!='fr'?'/'+jQuery('html').attr('lang')+'/':'/')+'#top');
    });
}); 
