jQuery(document).ready(function() {


   jQuery.validate({
      form : 'form#id20,form#id31,form#id32,form#id35,form#id37,form#id17,form#id23,form#id27,form#id25,form#id29',
      onError : function() {
        console.log('Validation of form failed!');
        return false; 
      },
      onSuccess : function($this) {
        $.post('/wp-content/themes/rsparts/partials/send-form-pdf.php', jQuery($this).serialize(), function( success ) {
          console.log(success);
              if(success == 'sent'){
                  jQuery('.js-download-pdf .form-success').removeClass('hide');
              }
        });
        return false; 
      }
   });

   jQuery.validate({
     form : 'form#contact',
      onError : function() {
        console.log('Validation of form failed!');
        return false; 
      },
      onSuccess : function($this) {
        $.post('/wp-content/themes/rsparts/partials/send-form-contact.php', jQuery($this).serialize(), function( success ) {
            if(success == 'sent'){
                jQuery('#contact .form-success').removeClass('hide');
            }
        });
        return false; 
      }
   });


    jQuery.validate({
     form : 'form#share-page',
      onError : function() {
        console.log('Validation of form failed!');
        return false; 
      },
      onSuccess : function($this) {
        $.post('/wp-content/themes/rsparts/partials/send-form-page.php', jQuery($this).serialize(), function( success ) {
            if(success == 'sent'){
                jQuery('.js-share-page .form-success').removeClass('hide');
            }
        });
        return false; 
      }
   });


});


