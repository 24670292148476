jQuery( document ).ready(function() {
    if(!isMobile) {
        //setTimeout(slideLeft, 500);
        /*function slideLeft() {
             jQuery('.image').css('background-position-x', '500px');
        }*/
        setTimeout(fadeUp, 1000);
        function fadeUp() {
             jQuery('.image img').animate({ opacity: 1 }, 1000);  
        }
    }

});
